
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'

// No message specified.
extend('email', email)
// Override the default message.
extend('required', {
  ...required,
  message: 'This field is required'
})
export default {
  name: 'LayoutLogin',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  layout: 'empty',
  data: () => ({
    email: '',
    password: '',
    rememberMe: false,
    show1: false,
    msgErrors: null,
    msgErrorsAlert: false,
    loading: false
  }),
  mounted() {},
  methods: {
    async onSubmit() {
      // this.$refs.observer.validate().then((result) => {
      //   if (result) {
      //     console.log(result)
      //     alert('Form Submitted!')
      //     return
      //   }

      //   alert('Correct them errors!')
      // })
      this.loading = !this.loading
      this.invalid = !this.invalid
      this.msgErrorsAlert = false
      try {
        // await this.$axios.$get('sanctum/csrf-cookie')
        await this.$auth.loginWith('laravelSanctum', {
          data: { email: this.email, password: this.password }
        })
        if (this.$auth.loggedIn) {
          if (this.$auth.user.provinces) {
            this.$router.push(`/dashboard/${this.$auth.user.provinces[0].id}`)
          } else {
            this.$router.push(`/dashboard/`)
          }
        }
      } catch (error) {
        // console.log('log error', error.response)
        if (error.response.status === 401) {
          this.msgErrorsAlert = true
          this.msgErrors =
            'ไม่สามารถเข้าสู่ระบบได้ Email หรือ Password อาจไม่ถูกต้อง'
        }
        if (error.response.status === 500 || error.response.status === 404) {
          this.msgErrorsAlert = true
          this.msgErrors = 'มีบางอย่างผิดพลาดกรุณาติดต่อผู้ดูแลระบบ'
        }
        this.loading = !this.loading
        this.invalid = !this.invalid
      }
    }
  }
}
